<template lang='pug'>
.page-wrap
	h3 Page Details
	h6 {{path}}
</template>

<script>

export default{
	data(){
		return{
			path:String
		}
	},
	async created(){
		this.path = this.$route.params.path.join(',')//.replace(/,/g, '/');
		this.path += this.$route.query;
		console.log(this.$route.query);
	}
}

</script>

<style lang="stylus" scoped>
h3
	margin-bottom 30px
</style>